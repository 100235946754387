<ng-container *ngIf="vm$ | async as vm">
  <div class="{{ vm.value.length ? 'dropdown' : 'dropdown-no-title' }}">
    <span>{{ vm.value }}</span>
    <!-- Ellipses -->
    <ng-container [ngSwitch]="icon">
      <svg-icon
        *ngSwitchCase="'ellipses'"
        fontSize="24px"
        class="mx-1 transition-transform"
        key="more-vert"
      ></svg-icon>
      <!-- Down arrow chevron -->
      <svg-icon
        *ngSwitchDefault
        fontSize="24px"
        class="mx-1 transition-transform"
        [ngClass]="{ '-rotate-180': vm.expanded }"
        key="keyboard-arrow-down"
      ></svg-icon>
    </ng-container>
  </div>

  <div *ngIf="vm.expanded" class="expanded-menu">
    <ng-content></ng-content>
  </div>
</ng-container>
