import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ElementRef,
  HostListener,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { ItkIconsModule } from '@itk/icons';
import {
  combineLatest,
  distinctUntilChanged,
  first,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
} from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ItkIconsModule],
  selector: 'itk-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.style.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItkDropdownComponent {
  constructor(
    private readonly elementRef: ElementRef,
    @Inject(PLATFORM_ID) private readonly pid: string,
  ) {}

  private readonly valueSource = new ReplaySubject<string>(1);

  @Input() set value(x: undefined | null | string) {
    if (typeof x === 'string') {
      this.valueSource.next(x);
    }
  }

  @Input() icon = 'chevron';

  private readonly expandedSource = new Subject<boolean>();
  @Output() public readonly expanded$ = this.expandedSource.pipe(
    startWith(false),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  // Ability to "click outside" the component and collapse the UI
  @HostListener('document:click', ['$event.target'])
  clickOut(event: EventTarget): void {
    if (!isPlatformBrowser(this.pid)) return;
    if (this.elementRef.nativeElement.contains(event)) return;
    this.expandedSource.next(false);
  }

  @HostListener('click')
  clickIn(): void {
    this.expanded$.pipe(first()).subscribe((toggledState) => {
      this.expandedSource.next(!toggledState);
    });
  }

  public readonly vm$ = combineLatest({
    value: this.valueSource.asObservable(),
    expanded: this.expanded$,
  });
}
